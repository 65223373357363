import React, { useState, useEffect, useRef, useContext } from "react"
import styled from "styled-components"
import { Link, navigate, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import FacebookLogo from "../images/facebook.svg"
import InstaLogo from "../images/instagram.svg"
import TwitterLogo from "../images/twitter.svg"
import LinkedinLogo from "../images/linkedin.svg"

import PrimaryNav from "./footer/primary"
import SecondaryNav from "./footer/secondary"
import InView from "./in-view"
import { ButtonContext } from "./context/button-context"
import { NewsletterContext } from "./context/newsletter-context"
import CopyToClipboard from "../helpers/copy-clipboard"

const Footer = ({ isServices }) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      wordPress {
        menus {
          edges {
            node {
              name
              slug
              menuItems {
                edges {
                  node {
                    connectedObject {
                      ... on WordPress_Page {
                        slug
                        title
                      }
                      ... on WordPress_Landing {
                        slug
                        title
                      }
                    }
                  }
                }
              }
            }
          }
        }
        globalOptions {
          optionFields {
            logo {
              imageWhite {
                sourceUrl
                mediaItemId
                modified
                altText
                imageFile {
                  publicURL
                }
              }
            }
            contactInfos {
              labelLondon
              labelNyc
              address
              directionLink
              directionText
              email
              facebookLink
              instagramLink
              linkedinLink
              phone
              twitterLink
            }
            footer {
              backgroundImage {
                sourceUrl
                mediaItemId
                modified
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              logoList {
                image {
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1200, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
                link
              }
            }
          }
        }
      }
    }
  `)

  const [isFooterDesktop, setIsFooterDesktop] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [buttonTriggers, setButtonTriggers] = useContext(ButtonContext)
  const [newsletterTriggers, setNewsletterTriggers] = useContext(
    NewsletterContext
  )
  const footerRef = useRef()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsFooterDesktop(window.innerWidth > 1024)
    }
  }, [])

  // const ImageBG =
  //   data.wordPress.globalOptions.optionFields.footer.backgroundImage.imageFile.childImageSharp.fluid
  const { contactInfos } = data.wordPress.globalOptions.optionFields
  const navItems = data.wordPress.menus.edges
  const footerListImage =
    data.wordPress.globalOptions.optionFields.footer.logoList

  const footerMain = navItems.filter(
    footerMainItem => footerMainItem.node.name === "Footer navigation"
  )

  const footerSecondary = navItems.filter(
    footerSecondaryItem =>
      footerSecondaryItem.node.name === "Footer secondary navigation"
  )

  function handleResize() {
    if (typeof window !== `undefined`) {
      setIsFooterDesktop(window.innerWidth > 1024)
    }
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", handleResize)
    }
  }, [isFooterDesktop])

  return (
    <InView
      // element={isServices ? footerRef.current : null}
      element={footerRef.current}
      setToggle={setButtonTriggers}
      location="footer"
    >
      <PageFooter ref={footerRef}>
        <BgNav />

        <TopRow>
          {isFooterDesktop && (
            <>
              <PrimaryNav primary={footerMain} />
              <WrapExpertNav>
                <ExpertInTitle>Experts in</ExpertInTitle>
                <SecondaryNav secondary={footerSecondary} />
              </WrapExpertNav>
              <OfficeNav>
                <OfficesTitle>Offices</OfficesTitle>
                <Link activeClassName="active" to="/contact">
                  {contactInfos.labelLondon}
                </Link>
                <Link activeClassName="active" to="/contact">
                  {contactInfos.labelNyc}
                </Link>
              </OfficeNav>
            </>
          )}

          <WrapDetails>
            <SocialTitle>Follow</SocialTitle>
            <WrapSocials>
              <a
                href={contactInfos.twitterLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterLogo />
              </a>
              <a
                href={contactInfos.instagramLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstaLogo />
              </a>
              <a
                href={contactInfos.facebookLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookLogo />
              </a>
              <a
                href={contactInfos.linkedinLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinLogo />
              </a>
            </WrapSocials>
            <SignUpText onClick={() => setNewsletterTriggers(true)}>
              Sign up to our newsletter
            </SignUpText>
            <CopyToClipboard text={contactInfos.email} />
          </WrapDetails>
        </TopRow>

        <WrapImageList>
          {footerListImage.map((image, j) => (
            <a
              key={j}
              href={image.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImageListItem
                key={j}
                fluid={image.image.imageFile.childImageSharp.fluid}
              />
            </a>
          ))}
        </WrapImageList>
        <KotaLogoWhite>
          <Link to="/">
            <img
              src={
                data.wordPress.globalOptions.optionFields.logo.imageWhite
                  .imageFile.publicURL
              }
              alt="logo"
            />
          </Link>
        </KotaLogoWhite>
      </PageFooter>
    </InView>
  )
}

export default Footer

const PageFooter = styled.footer`
  position: relative;
  width: 100%;
  background: ${props => props.theme.colours.pink};
  color: ${props => props.theme.colours.white};
  font-size: 24px;
  font-family: ${props => props.theme.fonts.sofia};
  line-height: 1;
  padding: 10rem 8% 19rem 8%;
  z-index: 10;

  @media (max-width: 824px) {
    padding: 8rem 8% 10rem 8%;
  }
`

const BgNav = styled.div`
  background-color: ${props => props.theme.colours.footer};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const WrapExpertNav = styled.div`
  width: 27.5%;

  div {
    width: 100%;
  }
`

const ExpertInTitle = styled.p`
  font-size: 1.4rem;
  line-height: 1.25;
  letter-spacing: 0.2rem;
  margin-top: 1.5rem;
  text-transform: uppercase;
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.sofia};
  margin-left: 1.45rem;
`

const OfficeNav = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;

  a {
    font-size: 1.6rem;
    line-height: 2.45;
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 700;
    color: ${props => props.theme.colours.white};
    transition: color 0.2s ease-in-out;

    &:hover {
      color: ${props => props.theme.colours.pink};
    }

    &.active {
      border-bottom: 1px solid white;
    }
  }
`

const OfficesTitle = styled.p`
  font-size: 1.4rem;
  line-height: 1.25;
  letter-spacing: 0.2rem;
  margin-top: 1.5rem;
  text-transform: uppercase;
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.sofia};
`

const WrapImageList = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 9%;
  bottom: 100px;
  width: 550px;

  @media (max-width: 1024px) {
    justify-content: space-around;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    position: relative;
    max-width: 550px;
    margin-top: 0;
    flex-wrap: wrap;
  }

  @media (max-width: 650px) {
    position: relative;
    max-width: 300px;
    bottom: unset;
    left: 50%;
    margin-top: 2.5rem;
    flex-wrap: wrap;
    justify-content: center;
    transform: translateX(-50%);
  }

  a {
    display: flex;
    width: 120px;
    height: 35px;
    margin-right: 3rem;

    @media (max-width: 1024px) {
      /* width: 120px; */
      width: calc(20% - 2.5rem);
      margin-right: 2rem;
      margin-bottom: 1.5rem;
    }

    @media (max-width: 650px) {
      width: 50%;
      margin-right: 0;
      justify-content: center;
      margin-bottom: 3rem;
    }

    &:nth-child(2) {
      height: 40px !important;
      width: 120px !important;

      @media (max-width: 650px) {
        height: auto !important;
        width: 50% !important;
      }
    }

    &:nth-child(3) {
      margin-bottom: 0;
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
`

const ImageListItem = styled(Img)`
  width: 140px;
  margin-right: 2.5rem;

  @media (max-width: 1024px) {
    margin-right: 5rem;
  }

  @media (max-width: 650px) {
    width: 100px;
    height: 30px;
  }

  &:last-child {
    margin-right: 0;
  }

  & img {
    object-fit: contain !important;
    width: 140px !important;
  }
`

const KotaLogoWhite = styled.div`
  position: absolute;
  bottom: 100px;
  right: 8%;
  display: block;
  z-index: 200;

  @media (max-width: 1024px) {
    display: none;
  }

  a {
    display: block;
  }

  img {
    width: 60px;
    margin: 0;

    @media (min-width: 1700px) {
      width: 90px;
    }

    @media (max-width: 769px) {
      width: 50px;
    }
  }
`

const SocialTitle = styled.p`
  font-size: 1.4rem;
  line-height: 1.25;
  letter-spacing: 0.2rem;
  margin-top: 1.5rem;
  text-transform: uppercase;
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.sofia};

  @media (max-width: 650px) {
    font-size: 2rem;
  }
`

const WrapSocials = styled.div`
  margin: 3rem 0;

  @media (max-width: 1024px) {
    display: flex;
    justify-content: space-around;
    width: 60%;
    max-width: 250px;
    margin: 3rem auto;
    bottom: unset;
    position: relative;
  }

  @media (max-width: 650px) {
    position: relative;
  }

  a {
    margin-left: 2rem;
    max-width: 20px;

    @media (max-width: 1024px) {
      max-width: 100%;
      margin-left: 2rem;
    }

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      g {
        stroke: ${props => props.theme.colours.pink};
      }

      path {
        fill: ${props => props.theme.colours.pink};
      }
    }
  }

  svg {
    fill: transparent;
    width: 40px;

    g {
      stroke: ${props => props.theme.colours.white};
      fill: transparent;
      transition: stroke 0.2s ease-in-out;
    }

    path {
      fill: ${props => props.theme.colours.white};
      transition: fill 0.2s ease-in-out;
    }
  }
`

const SignUpText = styled.p`
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 700;
  margin: 0 0 4.5rem 0;
  color: ${props => props.theme.colours.white};
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${props => props.theme.colours.pink};
  }

  @media (max-width: 650px) {
    font-size: 2rem;
  }
`

const WrapDetails = styled.div`
  position: relative;
  text-align: right;
  margin-left: auto;

  @media (max-width: 1024px) {
    position: relative;
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    width: 100%;
  }

  h3 {
    color: ${props => props.theme.colours.white};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 2rem;
    line-height: 1.88;
    letter-spacing: -0.075rem;
    max-width: 100%;
    text-align: right;
    margin-right: 0;
    margin-bottom: 0;
    white-space: pre-wrap;

    @media (max-width: 1024px) {
      text-align: center;
      font-size: 3.6rem;
      line-height: 1.5;
      margin: 0 auto;
    }

    @media (max-width: 650px) {
      font-size: 3rem;
      max-width: 300px;
      margin: 0 auto;
    }
  }
`

const TopRow = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`
