import React, { useContext, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import usePortal from "react-useportal"
import CrossSvg from "../images/cross.svg"
import ContactForm from "./contact/form"
import { ButtonContext } from "./context/button-context"
import { ScrollbarContext } from "./context/scrollbar-context"
import CopyToClipboard from "../helpers/copy-clipboard"

const StartProject = () => {
  // eslint-disable-next-line no-unused-vars
  const [buttonTriggers, setButtonTriggers] = useContext(ButtonContext)
  const [scrollbar] = useContext(ScrollbarContext)
  const wrapRef = useRef()

  const data = useStaticQuery(graphql`
    query StartProjectQuery {
      wordPress {
        globalOptions {
          optionFields {
            contactInfos {
              emailProjects
              phone
              phoneNyc
            }
            startProject {
              ctaText
              formTitle
              introText
              sideText
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    const fixedPosition = ({ offset = 0 }) => {
      if (wrapRef.current) {
        // wrapRef.current.style.top = `${offset.y}px`
        wrapRef.current.style.transform = `translateY(${offset.y}px)`
      }
    }

    // Hooks into the Smooth Scrollbar instance that's passed as context
    // to create an artificially fixed element, sync to the current
    // Smooth Scrollbar offset
    if (scrollbar) {
      scrollbar.addListener(status => fixedPosition(status))
    }

    return function cleanupListener() {
      if (scrollbar) {
        scrollbar.removeListener(status => fixedPosition(status))
      }
    }
  }, [scrollbar])

  const { startProject } = data.wordPress.globalOptions.optionFields
  const startProjectContact =
    data.wordPress.globalOptions.optionFields.contactInfos

  const { openPortal, closePortal, isOpen, Portal } = usePortal({
    onOpen({ portal }) {
      portal.current.style.cssText = `
        position: absolute;
        height: 100vh;
        overflow: visible;
        z-index: 1000;
      `
    },
  })

  const buttonWrapperAnimation = useSpring({
    from: { opacity: 0, zIndex: 100 },
    to: {
      opacity:
        !buttonTriggers.footerTrigger &&
        !buttonTriggers.heroTrigger &&
        !buttonTriggers.crackingTrigger
          ? 1
          : 0,
      pointerEvents:
        !buttonTriggers.footerTrigger &&
        !buttonTriggers.heroTrigger &&
        !buttonTriggers.crackingTrigger
          ? "all"
          : "none",
      zIndex: 100,
    },
    config: { duration: 200 },
  })

  const buttonStartProjectAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1500,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  return (
    <>
      <StartProjectWrap ref={wrapRef}>
        <StartButtonWrap style={buttonWrapperAnimation}>
          <StartButton style={buttonStartProjectAnimation} onClick={openPortal}>
            {startProject.ctaText}
          </StartButton>
        </StartButtonWrap>
      </StartProjectWrap>

      <Portal>
        <Modal className={isOpen ? "active" : ""}>
          <ModalContents>
            <Close onClick={closePortal}>
              <CrossSvg />
            </Close>

            <WrapContent>
              <WrapLeft>
                <Title>{startProject.introText}</Title>

                <p>Contact us</p>
                <WrapEmail>
                  <CopyToClipboard text={startProjectContact.emailProjects} />
                </WrapEmail>
                <WrapPhone>
                  <span>London: </span>
                  <a href={`tel:${startProjectContact.phone}`}>
                    {startProjectContact.phone}
                  </a>
                </WrapPhone>
                <WrapPhone>
                  <span>New York: </span>
                  <a href={`tel:${startProjectContact.phoneNyc}`}>
                    {startProjectContact.phoneNyc}
                  </a>
                </WrapPhone>
              </WrapLeft>

              <WrapRight>
                <InsideWrap>
                  <h2>{startProject.formTitle}</h2>
                  <ContactForm />
                </InsideWrap>
              </WrapRight>
            </WrapContent>
          </ModalContents>
          <ModalBackground />
        </Modal>
      </Portal>
    </>
  )
}

const StartProjectWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  text-align: right;
  padding-right: 5rem;
  pointer-events: none;
  will-change: transform;
  z-index: 50;

  /* // On touch devices */
  @media (pointer: coarse) {
    position: fixed;
    will-change: transform;
    z-index: 100 !important;
  }
`

const StartButtonWrap = styled(animated.figure)`
  position: absolute;
  right: 3rem;
  bottom: 3rem;
  margin-bottom: 0;

  @media (pointer: coarse) {
    bottom: 8rem;
    position: fixed !important;
    z-index: 100 !important;
  }
`

const StartButton = styled(animated.button)`
  background-color: ${props => props.theme.colours.pink};
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.circular};
  letter-spacing: 0.1rem;
  font-weight: 700;
  text-transform: uppercase;
  border: 2px solid ${props => props.theme.colours.pink};
  border-radius: 50px;
  padding: 25px 35px;
  font-size: 12px;
  pointer-events: inherit;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  border: 0.25s ease-in-out;
  z-index: 5;

  @media (max-width: 650px) {
    right: 2.5rem;
    width: 25rem;
    font-size: 10px;
    padding: 15px 25px;
    border: 0;
  }

  &:hover {
    background-color: ${props => props.theme.colours.white};
    color: ${props => props.theme.colours.pink};
    border: 2px solid ${props => props.theme.colours.pink};

    @media (max-width: 650px) {
      border: 0;
    }
  }
`

const ModalContents = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.9);
  transition: transform 0.3s ease;
  overflow: hidden;
  z-index: 5;
`

const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
`

const Modal = styled(animated.aside)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 100;
  overflow: hidden;

  @media (max-width: 650px) {
    height: 100vh;
  }

  &.active {
    opacity: 1;
    pointer-events: all;

    ${ModalContents} {
      transform: scale(1);
    }
  }
`

const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 30px;
  outline: 0;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
  svg {
    width: 35px;
    height: 35px;
  }
`

const WrapLeft = styled.div`
  width: 50%;

  @media (max-width: 1024px) {
    width: 100%;
    margin: 15rem 15% 2.5rem 15%;
  }

  @media (max-width: 650px) {
    width: 100%;
    margin: 15rem 0 7.5rem 0;
  }

  p {
    font-size: 2rem;
    color: ${props => props.theme.colours.lightGrey};
    padding-top: 5rem;

    @media (max-width: 1024px) {
      padding-top: 2.5rem;
      font-size: 2rem;
    }

    @media (max-width: 650px) {
      padding-top: 2.5rem;
      font-size: 2.85rem;
    }
  }

  a {
    display: block;
    font-family: ${props => props.theme.fonts.sofia};
    color: ${props => props.theme.colours.pink};
    font-size: 3.4rem;

    @media (max-width: 1024px) {
      font-size: 5rem;
    }

    @media (max-width: 650px) {
      font-size: 3.8rem;
    }
  }
`

const Title = styled.h2`
  width: 80%;
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 4.5rem;
  color: ${props => props.theme.colours.pink};

  @media (max-width: 1024px) {
    width: 100%;
    font-size: 4.5rem;
  }

  @media (max-width: 650px) {
    width: 100%;
  }
`

const WrapPhone = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 1.5;

  @media (max-width: 1224px) {
    flex-direction: column;
    margin-bottom: 1.5rem;
  }

  a {
    font-size: 2.6rem !important;

    @media (min-width: 1920px) {
      font-size: 3.4rem !important;
    }

    @media (max-width: 1024px) {
      font-size: 2.8rem !important;
    }

    @media (max-width: 650px) {
      font-size: 3.8rem !important;
    }
  }

  @media (max-width: 650px) {
    margin-bottom: 2.5rem;
  }

  span {
    font-family: ${props => props.theme.fonts.sofia};
    color: ${props => props.theme.colours.grey};
    font-size: 2.6rem;

    @media (min-width: 1920px) {
      font-size: 3.4rem;
    }

    @media (max-width: 1024px) {
      font-size: 2.8rem;
    }

    @media (max-width: 650px) {
      font-size: 3.8rem;
    }
  }
`

const WrapEmail = styled.div`
  font-family: ${props => props.theme.fonts.sofia};
  color: ${props => props.theme.colours.pink};
  font-size: 3.4rem;
  margin-top: 3rem;

  @media (max-width: 1024px) {
    font-size: 5rem;
  }

  @media (max-width: 650px) {
    font-size: 3.8rem;
    margin-top: 4rem;
  }

  div {
    color: ${props => props.theme.colours.pink} !important;

    @media (max-width: 650px) {
      font-size: 3.8rem !important;
      margin-bottom: 2rem !important;
    }
  }
`

const InsideWrap = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 10rem;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
`

const WrapRight = styled.div`
  position: relative;
  width: 50%;

  @media (max-width: 1024px) {
    padding: 5rem 15% 15rem 15%;
    width: 100%;
  }

  @media (max-width: 650px) {
    padding: 7.5rem 0 15rem 0;
    width: 100%;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background-color: ${props => props.theme.colours.grey};
    margin-right: 100px;
    display: block;

    @media (max-width: 1024px) {
      width: 100%;
      height: 1px;
      display: none;
    }

    @media (max-width: 650px) {
      width: 100%;
      height: 1px;
      display: block;
    }
  }

  h2 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 45px;

    @media (max-width: 1024px) {
      font-size: 5.5rem;
    }

    @media (max-width: 650px) {
      font-size: 30px;
    }
  }
`

const WrapContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  height: 85%;
  overflow-y: scroll;
  margin: 0 auto;
  padding: 3rem 0;

  @media (max-width: 1660px) and (max-height: 740px) {
    width: 85%;
    height: 95%;
    overflow-y: scroll;
    scrollbar-width: none;
    padding: 3rem;
  }

  @media (max-width: 1320px) and (max-height: 675px) {
    width: 85%;
    height: 90%;
    overflow-y: scroll;
    scrollbar-width: none;
    padding: 0 3rem;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    position: relative;
    margin: 0 auto;
    padding: 3rem;
  }

  &::-webkit-scrollbar {
    width: 0 !important;
  }
`

export default StartProject
