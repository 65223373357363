import React from 'react'
import Helmet from 'react-helmet'

const Bugheard = () => (
  <>
    {process.env.WPGRAPHQL_ENDPOINT !== 'https://content.kota.co.uk/graphql' && (
      <Helmet>
        <script
          type="text/javascript"
          src="https://www.bugherd.com/sidebarv2.js?apikey=bg15og5ffxqyaod0hvj7pa"
          async="true"
        ></script>
      </Helmet>
    )}
  </>
)

export default Bugheard
