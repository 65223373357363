/* eslint-disable jsx-a11y/html-has-lang */
import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Helmet from "react-helmet"
import { isMobile } from "react-device-detect"
import Scrollbar from "react-smooth-scrollbar"
import { ScrollbarProvider } from "./context/scrollbar-context"

import "../fonts/fonts.css"

import Bugheard from "./bugheard"
import Footer from "./footer"
import StartProject from "./start-project"

const Layout = ({ children }) => {
  const [isContact, setisContact] = useState(false)
  const [isSignup, setisSignup] = useState(false)
  const [isServices, setisServices] = useState(false)
  const [isBlogs, setisBlogs] = useState(false)
  const [scrollbarInstance, setScrollbarInstance] = useState(undefined)
  const scrollRef = useRef()
  const [isTouch, setIsTouch] = useState(undefined)

  useEffect(() => {
    setScrollbarInstance(scrollRef.current.scrollbar)
  }, [scrollbarInstance])

  // Check if we're using a touch device and restrict to
  // likely a phone or tablet sizes. Only enable Smooth Scroll
  // on non-touch devices
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const checkTouchDevice = () => "ontouchstart" in window

      if (checkTouchDevice() && window.innerWidth < 1050) {
        if (scrollbarInstance) {
          setIsTouch(true)
          scrollbarInstance.destroy()
        }
      }
    }
  }, [scrollbarInstance])

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setisContact(
        window.location.pathname === "/contact" ||
          window.location.pathname === "/contact/"
      )

      setisSignup(
        window.location.pathname === "/signup" ||
          window.location.pathname === "/signup/"
      )

      setisServices(window.location.href.indexOf("/services/") > -1)

      setisBlogs(window.location.href.indexOf("blog") > -1)
    }
  }, [isContact])

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const urlParams = window.location.search
      if (urlParams.includes("?gclid")) {
        localStorage.setItem("referrer", "PPC")
      }
    }
  }, [])

  return (
    <>
      {isTouch && (
        <Helmet>
          <body className="has-touch" />
          <html className="has-touch" />
        </Helmet>
      )}
      <Scrollbar
        ref={scrollRef}
        alwaysShowTracks={!isMobile}
        syncCallbacks
        renderByPixels
      >
        <ScrollbarProvider scrollbarInstance={scrollbarInstance}>
          <Main className="main">
            {isContact || isBlogs || isSignup ? null : <StartProject />}
            {children}
          </Main>
          {isContact || isSignup ? null : <Footer isServices={isServices} />}
        </ScrollbarProvider>
      </Scrollbar>
      <Bugheard />
    </>
  )
}

const Main = styled.main`
  position: relative;
  overflow: visible;
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
