import React, { useContext } from 'react'
import { Link, navigate } from 'gatsby'
import styled from 'styled-components'
import { TransitionContext } from '../context/transition-context'

const Primary = ({ primary }) => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)

  return (
    <WrapPrimaryNav>
      <ul>
        {primary[0].node.menuItems.edges.map((footerNavItem, index) => {
          const slug =
            footerNavItem.node.connectedObject.slug === 'home'
              ? ''
              : footerNavItem.node.connectedObject.slug
          return (
            <li key={[index]}>
              <Link
                activeClassName="active"
                to={`/${slug}`}
                onClick={event => {
                  event.preventDefault()
                  setTransitionActive(true)
                  setTimeout(() => {
                    navigate(`/${slug}`)
                    setTransitionActive(false)
                  }, 500)
                }}
              >
                {footerNavItem.node.connectedObject.title}
              </Link>
            </li>
          )
        })}
      </ul>
    </WrapPrimaryNav>
  )
}

export default Primary

const WrapPrimaryNav = styled.div`
  position: relative;
  margin: 0 2rem 0 0;
  align-self: flex-start;
  width: 15%;

  li {
    list-style: none;
    margin: 0;
    line-height: 1.6;

    a {
      font-size: 2rem;
      color: ${props => props.theme.colours.white};
      transition: color 0.2s ease-in-out;

      &:hover {
        color: ${props => props.theme.colours.pink};
      }

      &.active {
        border-bottom: 1px solid white;
      }
    }
  }
`
